import { useMemo } from 'react';
import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import merge from 'deepmerge';
import isEqual from 'lodash.isequal';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient;

const wsLink = process.browser
  ? new GraphQLWsLink(
      createClient({
        url: process.env.NEXT_PUBLIC_BACKEND_WSS
      })
    )
  : null;

const httplink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_BACKEND_API,
  credentials: 'same-origin'
});

const link =
  typeof window !== 'undefined'
    ? split(
        // split based on operation type
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        httplink
      )
    : httplink;

/* eslint-disable no-underscore-dangle */
function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link,
    cache: new InMemoryCache({
      typePolicies: {
        Message: {
          fields: {
            answers: {
              merge(existing = [], incoming = []) {
                if (!incoming) return existing;
                const merged = [...existing];
                incoming.forEach((incomingMessage) => {
                  const index = existing.findIndex(
                    (existing) => existing.__ref === incomingMessage.__ref
                  );
                  if (index > -1) {
                    merged[index] = incomingMessage;
                  } else {
                    merged.push(incomingMessage);
                  }
                });
                return merged;
              }
            }
          }
        },
        Query: {
          fields: {
            messageFeed: {
              keyArgs: false,
              merge(existing, incoming, { args: { cursor } }) {
                if (!incoming?.messages.length) return existing;
                if (!existing?.messages.length) return incoming;

                //const fromSubscription = cursor ? false : true;
                const merged = [...existing.messages];
                //const newMessageFromSubscription = [];

                incoming.messages.forEach((incomingMessage) => {
                  const index = existing.messages.findIndex(
                    (existing) => existing.__ref === incomingMessage.__ref
                  );
                  if (index > -1) {
                    merged[index] = incomingMessage;
                  } else {
                    merged.push(incomingMessage);
                    // if (fromSubscription) {
                    //   newMessageFromSubscription.push(incomingMessage);
                    // } else {
                    //   merged.push(incomingMessage);
                    // }
                  }
                });

                return {
                  ...incoming,
                  messages: merged
                };
              }
            }
          }
        }
      }
    })
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        )
      ]
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}
/* eslint-enable no-underscore-dangle */

export function addApolloState(client, pageProps) {
  const newPageProps = { ...pageProps };
  if (pageProps?.props) {
    newPageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return newPageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
