import React, { useState, useEffect } from 'react';
import { appWithTranslation } from 'next-i18next';
import { getUserLocale } from 'get-user-locale';
import { ApolloProvider } from '@apollo/client';
import Cookies from 'js-cookie';
import { RecoilRoot } from 'recoil';
import { useRouter } from 'next/router';
import { useApollo } from '../lib/apolloClient';

import '../styles/global.css';

const App = function ({ Component, pageProps }) {
  const [locale, setLocale] = useState('fr');
  const apolloClient = useApollo(pageProps);
  const router = useRouter();

  useEffect(() => {
    // check if we are on client side
    if (!process.browser) return;
    // the cookie NEXT_LOCALE override the locale detection
    const userLocale =
      Cookies.get('NEXT_LOCALE') ||
      getUserLocale().split('-')[0] ||
      getUserLocale();
    // no change if locale is fr, as it is the default locale
    if (
      locale !== userLocale &&
      (userLocale === 'de' ||
        userLocale === 'en' ||
        userLocale === 'es' ||
        userLocale === 'pt' ||
        userLocale === 'fr')
    ) {
      // redirect the user to the same page with the right locale
      router.push(router.asPath, router.asPath, { locale: userLocale });
      setLocale(userLocale);
    }
  }, [process.browser]);

  const mutedConsole = (console) => ({
    ...console,
    warn: (...args) =>
      args[0].includes('Duplicate atom key') ? null : console.warn(...args)
  });
  global.console = mutedConsole(global.console);

  return (
    <RecoilRoot>
      <ApolloProvider client={apolloClient}>
        <Component {...pageProps} />
      </ApolloProvider>
    </RecoilRoot>
  );
};

export default appWithTranslation(App);
